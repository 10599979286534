import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api, { institutionsUrl, rolesUrl, usersUrl } from '../../constants/api';
import { createUser, updateUser } from '../../store/session/usersSlice';
import Select from 'react-select';

const UserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [roles, setRoles] = useState([]);
  const [instances, setInstances] = useState([]);
  const [userDetails, setUserDetails] = useState({
    instances: [],
  });
  const navigate = useNavigate();

  const createNewUser = async () => {
    await dispatch(createUser(userDetails));
    navigate('/users');
  };

  const editCurrentUser = async () => {
    await dispatch(updateUser(userDetails));
    navigate('/users');
  };

  useEffect(() => {
    getRoles();
    getInstances();

    return () => {};
  }, []);

  useEffect(() => {
    getUserDetails();
    return () => {};
  }, [id]);

  const getUserDetails = async () => {
    const { data } = await api.get(`${usersUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setUserDetails(data.data);
  };

  const getRoles = async () => {
    const { data } = await api.get(rolesUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setRoles(data.data);
  };

  console.log(userDetails);

  const getInstances = async () => {
    const { data } = await api.get(institutionsUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setInstances(data.data);
  };

  const updateUserDetails = (val) => {
    setUserDetails({ ...userDetails, [val.target.name]: val.target.value });
  };

  return (
    <div className='container mx-auto mt-32 text-left flex flex-col gap-4'>
      <h2 className='font-bold text-xl'>
        {id == 'new' ? 'Create New' : 'Edit'} User
      </h2>
      <hr className='my-4' />
      <div className='flex flex-col'>
        <label htmlFor='firstname'>First Name</label>
        <input
          className='p-2 rounded-md border'
          id='firstname'
          placeholder='John'
          name='firstname'
          onChange={updateUserDetails}
          value={userDetails.firstname}
        />
      </div>
      <div className='flex flex-col'>
        <label htmlFor='surname'>Surname</label>
        <input
          className='p-2 rounded-md border'
          id='surname'
          placeholder='Doe'
          name='surname'
          onChange={updateUserDetails}
          value={userDetails.surname}
        />
      </div>
      <div className='flex flex-col'>
        <label htmlFor='email'>Email</label>
        <input
          className='p-2 rounded-md border'
          id='email'
          placeholder='john@example.com'
          type={'email'}
          name='email'
          onChange={updateUserDetails}
          value={userDetails.email}
        />
      </div>
      <div className='flex flex-col'>
        <label htmlFor='role'>Role</label>
        <select
          className='p-2 rounded-md border'
          id='role'
          placeholder='Select a Role'
          onChange={(e) =>
            setUserDetails({ ...userDetails, roleId: e.target.value })
          }
          value={userDetails.roleId}
        >
          <option value={-1}>Select a Role</option>
          {roles.map((role) => (
            <option value={role.role_id}>{role.roleName}</option>
          ))}
        </select>
      </div>
      <div className='flex flex-col'>
        <label htmlFor='instance'>Instance</label>
        <Select
          id='instance'
          placeholder='Select an Instance'
          onChange={(e) =>
            setUserDetails({
              ...userDetails,
              instances: e
                ? e.map((v) => ({ id: v.value, instanceName: v.label }))
                : [],
            })
          }
          value={userDetails.instances.map((i) => ({
            value: i.id,
            label: i.instanceName,
          }))}
          isMulti
          options={instances.map((i) => ({
            value: i.id,
            label: i.instanceName,
          }))}
        />
      </div>
      <div className='flex gap-4 mt-4'>
        <button
          onClick={() => {
            if (id === 'new') {
              createNewUser();
            } else {
              editCurrentUser();
            }
          }}
          className='bg-primary px-6 py-2 font-bold text-white rounded-md min-w-[200px]'
        >
          Save
        </button>
        <Link
          to={'/users'}
          className=' px-6 py-2 font-bold text-primary rounded-md min-w-[200px]'
        >
          Cancel
        </Link>
      </div>
    </div>
  );
};

export default UserDetail;
