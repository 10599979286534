import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../store/session/authSlice';

const Header = ({ showTabs = true }) => {
  const { me } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logout());
    document.location.reload();
  };

  return (
    <div className='fixed top-0 left-0 right-0 flex flex-col'>
      <div className='w-full bg-[rgb(247,184,30)] h-16 flex items-center px-2 justify-between border-b'>
        <div className='flex items-center'>
          <img src='/logo.png' className='h-12' />
        </div>
        <div className='flex items-center justify-end gap-4'>
          <div className='flex items-end flex-col justify-center'>
            <p className='font-bold leading-none text-white'>
              Welcome, {me.firstname}!
            </p>
            <div className='flex'>
              {me.role.roleName === 'Site Admin' && (
                <>
                  {window.location.pathname != '/users' ? (
                    <Link className='text-blue-400 font-semibold' to='/users'>
                      Admin
                    </Link>
                  ) : (
                    <Link className='text-blue-400 font-semibold' to='/'>
                      Home
                    </Link>
                  )}
                  <p className='font-bold mx-1 text-white'>|</p>
                </>
              )}
              <button
                className='text-blue-400 font-semibold mr-1'
                onClick={logoutUser}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      {showTabs && (
        <div className='flex items-center justify-evenly gap-8 bg-gray-200 pt-2 px-2'>
          <Link
            to='/'
            className={`${
              window.location.pathname === '/' ? 'text-primary bg-gray-50' : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Overview
          </Link>
          <Link
            to='/current'
            className={`${
              window.location.pathname === '/current'
                ? 'text-primary bg-gray-50'
                : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Current Courses
          </Link>
          <Link
            to='/upcoming'
            className={`${
              window.location.pathname === '/upcoming'
                ? 'text-primary bg-gray-50'
                : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Upcoming Courses
          </Link>
          <Link
            to='/past'
            className={`${
              window.location.pathname === '/past'
                ? 'text-primary bg-gray-50'
                : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Past Course Results
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
