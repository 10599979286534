import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import api, { upcomingCoursesUrl } from '../../constants/api';

const UpcomingCourses = () => {
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, me } = useSelector((state) => state.auth);
  const { instanceId, selectedInstitution, atpCode } = useSelector(
    (state) => state.institution
  );

  useEffect(() => {
    getUpcomingCourses();

    return () => {};
  }, []);

  const getUpcomingCourses = async () => {
    setLoading(true);
    const { data } = await api.get(upcomingCoursesUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        instance: selectedInstitution?.id,
        atpCode: atpCode,
      },
    });
    setLoading(false);

    console.log(data.data);
    setCourseData(data.data);
  };
  return (
    <div className='container mx-auto mt-32 mb-10'>
      <h1 className='text-xl my-4 font-bold'>Upcoming Courses</h1>
      <Table
        title='Enrolments for upcoming courses'
        headers={[
          { key: 'Course Code', label: 'Course Code' },
          { key: 'Course Start Date', label: 'Course Start Date' },
          { key: 'Course End Date', label: 'Course End Date' },
          { key: 'First Name', label: 'First Name' },
          { key: 'Surname', label: 'Surname' },
          { key: 'Employer', label: 'Employer' },
          { key: 'Country', label: 'Country' },
          { key: 'Enrolled', label: 'Status' },
        ]}
        entries={courseData.map((cd) => {
          return {
            ...cd,
            color: colourMapping(cd.Enrolled),
          };
        })}
        showActions={false}
        showPagination={false}
        loading={loading}
        colorColumns
      />
    </div>
  );
};

const colourMapping = (code) => {
  switch (code) {
    case 'Enrolled':
      return 'bg-primary text-white font-bold';
    case 'Not Enrolled':
      return 'bg-orange-400 font-bold';
  }
};

export default UpcomingCourses;
