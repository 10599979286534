import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Input from '../../components/Input';
import api, { forgotUrl } from '../../constants/api';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const submitForgot = async () => {
    setLoading(true);
    try {
      const { data } = await api.post(forgotUrl, {
        email,
      });
      if (data.success) {
        setSuccess(true);

        setTimeout(() => {
          navigate('/login');
        }, 4000);
      }
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className='w-full h-screen flex justify-center items-center flex-col'>
      <img src='/logo-single.png' className='w-40 mb-10' />
      <div className='flex flex-col gap-4 w-full sm:w-[500px] p-10 tile'>
        <p className='font-bold text-2xl leading-3'>Forgot Password?</p>
        <p className='font-semibold opacity-50 text-xl mb-8'>
          Enter your email below to receive a reset link
        </p>
        <Input
          placeholder='Email'
          onChange={(val) => setEmail(val.target.value)}
        />
        {success ? (
          <p className='font-bold text-link'>
            Please check your email to finish resetting your password.
            Redirecting to login screen...
          </p>
        ) : (
          <br />
        )}
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Button onClick={submitForgot}>Submit</Button>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
