import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import {
  getEnrolledInCurrent,
  getEnrolledInPast,
  getEnrolledInUpcoming,
  getFunnelValues,
  getInstances,
  getInstitutionDetails,
  getInstitutions,
  setAtpCode,
  setInstitution,
} from '../../store/session/institutionSlice';
import Select from 'react-select';
import FunnelChart from '../../components/FunnelChart/FunnelChart';

const Home = () => {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.auth);
  const {
    currentEnrolledstatus,
    currentEnrolled,
    upcomingEnrolledstatus,
    upcomingEnrolled,
    institutionDetailsStatus,
    institutionDetails,
    pastEnrolled,
    pastEnrolledStatus,
    selectedInstitution,
    institutions,
    status,
    funnelValues,
    funnelStatus,
    atpCodes,
    atpCode,
  } = useSelector((state) => state.institution);

  useEffect(() => {
    if (!selectedInstitution) return;

    if (currentEnrolledstatus === 'idle') {
      dispatch(getEnrolledInCurrent());
    }
    if (upcomingEnrolledstatus === 'idle') {
      dispatch(getEnrolledInUpcoming());
    }
    if (pastEnrolledStatus === 'idle') {
      dispatch(getEnrolledInPast());
    }
    if (institutionDetailsStatus === 'idle') {
      dispatch(getInstitutionDetails());
    }
    if (funnelStatus === 'idle') {
      dispatch(getFunnelValues());
    }

    return () => {};
  }, [selectedInstitution, atpCode]);

  useEffect(() => {
    if (me.instances.length > 0 && me.role.roleName.toLowerCase() === 'user') {
      dispatch(
        setInstitution({
          selectedInstitution: me.instances[0],
        })
      );
    } else {
      if (status === 'idle') {
        dispatch(getInstitutions());
        dispatch(getInstances());
      }
    }

    return () => {};
  }, [me]);

  return (
    <div className='container mx-auto mt-36 flex flex-col justify-center items-center'>
      <div className='pb-8 w-full flex justify-center items-center'>
        {me.role.roleName.toLowerCase() === 'user' ? (
          <>
            {me.instances.length > 0 && me.instances.length === 1 ? (
              <h1 className='text-3xl font-bold'>
                {me.instances[0].instanceName}
              </h1>
            ) : (
              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '1.5rem',
                    fontWeight: '700',
                  }),
                  container: (styles) => ({
                    ...styles,
                    minWidth: '300px',
                  }),
                }}
                value={{
                  label: selectedInstitution?.instanceName,
                  value: selectedInstitution?.id,
                }}
                options={me.instances.map((i) => {
                  return {
                    label: i.instanceName,
                    value: i.id,
                  };
                })}
                onChange={(val) => {
                  dispatch(
                    setInstitution({
                      selectedInstitution: {
                        id: val.value,
                        instanceName: val.label,
                      },
                    })
                  );
                }}
              />
            )}
          </>
        ) : (
          <>
            <Select
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: 'transparent',
                  border: 'none',
                  fontSize: '1.5rem',
                  fontWeight: '700',
                }),
                container: (styles) => ({
                  ...styles,
                  minWidth: '300px',
                }),
              }}
              value={{
                label: selectedInstitution?.instanceName,
                value: selectedInstitution?.id,
              }}
              options={institutions.map((i) => {
                return {
                  label: i.instanceName,
                  value: i.id,
                };
              })}
              onChange={(val) => {
                dispatch(
                  setInstitution({
                    selectedInstitution: {
                      id: val.value,
                      instanceName: val.label,
                    },
                  })
                );
              }}
            />
          </>
        )}
      </div>
      <div className='flex justify-between w-full'>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Total Students</p>
          <p>{institutionDetails.total ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Students Enrolled</p>
          <p>{institutionDetails.enrolled ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Students Active</p>
          <p>{institutionDetails.active ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Students Graded</p>
          <p>{institutionDetails.graded ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>ATP Code</p>
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: 'transparent',
                fontWeight: '700',
              }),
              container: (styles) => ({
                ...styles,
              }),
            }}
            placeholder='All'
            options={atpCodes}
            isClearable
            value={atpCode ? { label: atpCode, value: atpCode } : null}
            onChange={(val) => {
              if (val) {
                dispatch(setAtpCode(val.value));
              } else {
                dispatch(setAtpCode(null));
              }
            }}
          />
        </div>
      </div>
      <div className='grid grid-flow-col w-full pt-12 gap-8'>
        <div className='flex col-span-2 flex-col text-left gap-8'>
          <div>
            <Table
              title='Students Enrolled for Current Courses'
              headers={[
                { key: 'Course Code', label: 'Course Code' },
                { key: 'Course Start Date', label: 'Course Start Date' },
                { key: 'Course End Date', label: 'Course End Date' },
                { key: 'Enrolled Students', label: 'Students Enrolled' },
                {
                  key: 'Proj to pass',
                  label: 'Students Proj to Pass',
                },
                {
                  key: 'Marginally Behind',
                  label: 'Students Marginally Behind',
                },
                {
                  key: 'Proj to fail',
                  label: 'Students Proj to Fail',
                },
              ]}
              showPagination={false}
              entries={currentEnrolled}
              showActions={false}
            />
          </div>
          <div>
            <Table
              title='Students Enrolled for Upcoming Courses'
              headers={[
                { key: 'Course Code', label: 'Course Code' },
                { key: 'Course Start Date', label: 'Course Start Date' },
                { key: 'Course End Date', label: 'Course End Date' },
                { key: 'Enrolled Students', label: 'Students Enrolled' },
              ]}
              showPagination={false}
              entries={upcomingEnrolled}
              showActions={false}
            />
          </div>
          <div>
            <Table
              title='Students Completing Past Courses'
              headers={[
                { key: 'Course Code', label: 'Course Code' },
                { key: 'Course Start Date', label: 'Course Start Date' },
                { key: 'Course End Date', label: 'Course End Date' },
                { key: 'Enrolled Students', label: 'Students Enrolled' },
                { key: 'Passed', label: 'Students Passed' },
                { key: 'Failed', label: 'Students Failed' },
              ]}
              showPagination={false}
              entries={pastEnrolled}
              showActions={false}
            />
          </div>
        </div>
        <div className='border p-4 col-span-1 bg-white rounded-md flex flex-col gap-4 h-fit'>
          <p>Current Activity</p>
          <FunnelChart
            values={{
              total: funnelValues ? funnelValues['Total Students'] : 0,
              enrolled: funnelValues ? funnelValues['Enrolled Students'] : 0,
              active: funnelValues ? funnelValues['Active Students'] : 0,
              pass: funnelValues ? funnelValues['Proj to pass'] : 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
