import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import api, { pastCoursesUrl } from '../../constants/api';

const PastResults = () => {
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { instanceId, selectedInstitution, atpCode } = useSelector(
    (state) => state.institution
  );

  useEffect(() => {
    getPastCourses();

    return () => {};
  }, []);

  const getPastCourses = async () => {
    setLoading(true);
    const { data } = await api.get(pastCoursesUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        instance: selectedInstitution?.id,
        atpCode: atpCode,
      },
    });
    setLoading(false);
    setCourseData(data.data);
  };

  return (
    <div className='container mx-auto mt-32 mb-10'>
      <h1 className='text-xl my-4 font-bold'>Past Course Results</h1>
      <Table
        title='Student Results'
        headers={[
          { key: 'Course Code', label: 'Course Code' },
          { key: 'Course Start Date', label: 'Course Start Date' },
          { key: 'Course End Date', label: 'Course End Date' },
          { key: 'First Name', label: 'First Name' },
          { key: 'Surname', label: 'Surname' },
          { key: 'Employer', label: 'Employer' },
          { key: 'Country', label: 'Country' },
          { key: 'Last Access', label: 'Last Access' },
          { key: 'Pass Mark', label: 'Pass Mark' },
          { key: 'Grade', label: 'Grade' },
          { key: 'Benchmark', label: 'Benchmark' },
          { key: 'Login Days', label: 'Login Days' },
        ]}
        entries={courseData.map((cd) => {
          return {
            ...cd,
            color: colourMapping(cd.StudProg),
          };
        })}
        showActions={false}
        showPagination={false}
        loading={loading}
        colorColumns
      />
    </div>
  );
};

const colourMapping = (code) => {
  switch (code) {
    case '1':
      return 'bg-green-400';
    case '2':
      return 'bg-blue-400';
    case '3':
      return 'bg-orange-400';
    default:
    case '4':
      return 'bg-red-400';
  }
};

export default PastResults;
