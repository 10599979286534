import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Input from '../../components/Input';
import api, { resetUrl } from '../../constants/api';

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitForgot = async () => {
    if (password !== confirmPassword || !password) {
      alert('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      const { data } = await api.post(resetUrl, {
        password,
        token,
      });
      navigate('/login');
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className='w-full h-screen flex justify-center items-center flex-col'>
      <img src='/logo-single.png' className='w-40 mb-10' />
      <div className='flex flex-col gap-4 w-full sm:w-[500px] p-10 tile'>
        <p className='font-bold text-2xl leading-3 mb-4'>Create New Password</p>
        <Input
          placeholder='New Password'
          onChange={(val) => setPassword(val.target.value)}
          type='password'
        />
        <Input
          placeholder='Confirm New Password'
          onChange={(val) => setConfirmPassword(val.target.value)}
          type='password'
        />
        <br />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Button onClick={submitForgot}>Submit</Button>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
