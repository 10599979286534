import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api, {
  currentEnrolledCoursesUrl,
  funnelUrl,
  instancesUrl,
  institutionDetailsUrl,
  institutionsUrl,
  pastEnrolledCoursesUrl,
  upcomingEnrolledCoursesUrl,
  usersUrl,
} from '../../constants/api';

const initialState = {
  selectedInstitution: null,
  instanceId: null,
  status: 'idle',
  institutions: [],
  currentEnrolledstatus: 'idle',
  currentEnrolled: [],
  upcomingEnrolledstatus: 'idle',
  upcomingEnrolled: [],
  pastEnrolledStatus: 'idle',
  pastEnrolled: [],
  institutionDetailsStatus: 'idle',
  institutionDetails: {},
  funnelStatus: 'idle',
  funnelValues: null,
  atpCodes: [],
  instances: [],
  atpCode: null,
};

export const getInstitutionDetails = createAsyncThunk(
  'institution/details',
  async (_, { getState, requestId }) => {
    const response = await api.get(institutionDetailsUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: {
        instance: getState().institution.selectedInstitution.id,
        atpCode: getState().institution.atpCode,
      },
    });
    return response.data;
  }
);

export const getInstitutions = createAsyncThunk(
  'institution/get',
  async (_, { getState, requestId }) => {
    const response = await api.get(institutionsUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
    });
    return response.data;
  }
);

export const getInstances = createAsyncThunk(
  'instances/get',
  async (_, { getState, requestId }) => {
    const response = await api.get(instancesUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
    });
    return response.data;
  }
);

export const getEnrolledInCurrent = createAsyncThunk(
  'institution/enrolledCurrent',
  async (_, { getState, requestId }) => {
    const response = await api.get(currentEnrolledCoursesUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: {
        instance: getState().institution.selectedInstitution.id,
        atpCode: getState().institution.atpCode,
      },
    });
    return response.data;
  }
);

export const getEnrolledInUpcoming = createAsyncThunk(
  'institution/enrolledUpcoming',
  async (_, { getState, requestId }) => {
    const response = await api.get(upcomingEnrolledCoursesUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: {
        instance: getState().institution.selectedInstitution.id,
        atpCode: getState().institution.atpCode,
      },
    });
    return response.data;
  }
);

export const getEnrolledInPast = createAsyncThunk(
  'institution/enrolledPast',
  async (_, { getState, requestId }) => {
    const response = await api.get(pastEnrolledCoursesUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: {
        instance: getState().institution.selectedInstitution.id,
        atpCode: getState().institution.atpCode,
      },
    });
    return response.data;
  }
);

export const getFunnelValues = createAsyncThunk(
  'institution/funnelValues',
  async (_, { getState, requestId }) => {
    const response = await api.get(funnelUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: {
        instance: getState().institution.selectedInstitution.id,
        atpCode: getState().institution.atpCode,
      },
    });
    return response.data;
  }
);

export const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {
    setInstitution: (state, action) => {
      state.currentEnrolledstatus = 'idle';
      state.upcomingEnrolledstatus = 'idle';
      state.pastEnrolledStatus = 'idle';
      state.institutionDetailsStatus = 'idle';
      state.funnelStatus = 'idle';
      state.atpCode = null;
      if (action.payload.instanceId) {
        state.instanceId = action.payload.instanceId;
      }
      if (action.payload.selectedInstitution) {
        state.selectedInstitution = action.payload.selectedInstitution;
      }
    },
    setAtpCode: (state, action) => {
      state.currentEnrolledstatus = 'idle';
      state.upcomingEnrolledstatus = 'idle';
      state.pastEnrolledStatus = 'idle';
      state.institutionDetailsStatus = 'idle';
      state.funnelStatus = 'idle';
      state.atpCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstitutions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInstitutions.fulfilled, (state, action) => {
        state.status = 'success';
        state.institutions = action.payload.data;
        console.log(action.payload.data);
        state.selectedInstitution = action.payload.data[0];
      })
      .addCase(getInstitutions.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getInstances.fulfilled, (state, action) => {
        state.instances = action.payload.instances.map((i) => i.id);
        state.instanceId = action.payload.instances[0].id;
      })
      .addCase(getInstitutionDetails.fulfilled, (state, action) => {
        state.institutionDetailsStatus = 'success';
        state.atpCodes = action.payload.data.atpCodes;
        delete action.payload.data.atpCodes;
        state.institutionDetails = action.payload.data;
      })
      .addCase(getEnrolledInCurrent.fulfilled, (state, action) => {
        state.currentEnrolledstatus = 'success';
        state.currentEnrolled = action.payload.data;
      })
      .addCase(getEnrolledInUpcoming.fulfilled, (state, action) => {
        state.upcomingEnrolledstatus = 'success';
        state.upcomingEnrolled = action.payload.data;
      })
      .addCase(getEnrolledInPast.fulfilled, (state, action) => {
        state.pastEnrolledStatus = 'success';
        state.pastEnrolled = action.payload.data;
      })
      .addCase(getFunnelValues.fulfilled, (state, action) => {
        state.funnelStatus = 'success';
        state.funnelValues = action.payload.data;
      });
  },
});

export const { setInstitution, setInstanceId, setAtpCode } =
  institutionSlice.actions;

export default institutionSlice.reducer;
